import { getModels, getModelsByBrand } from './model.api';

function getModelsByBrands({ locationId, budget, dateId }) {
  return getModels({ locationId, budget, dateId, isExcluRequalification: 1 }).then(res => res.data);
}

async function getModelsByBrandFormatted({ brand, filter }) {
  const { data } = await getModelsByBrand(brand);

  return data
    .map(
      ({ modele, idmodele }) =>
        modele.toLowerCase().includes(filter.toLowerCase()) && {
          label: modele,
          value: idmodele,
        }
    )
    .filter(Boolean);
}

export default { getModelsByBrands, getModelsByBrandFormatted };
