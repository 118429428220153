import styled from 'styled-components';
import { Element } from 'react-scroll';

export const DesktopContentWrapper = styled.main`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin: 6.25rem 0 0;
  text-align: center;
`;

export const ScrollAnchor = styled(Element)`
  margin-top: 2rem;
  width: 100%;
`;

export const SectionTitle = styled.h2`
  font-size: 1.5rem;
  font-weight: 700;
  margin: 3rem 1.25rem 0.625rem;
  text-transform: uppercase;
`;

export const SectionSubtitle = styled.h3`
  font-size: 1rem;
  font-weight: 400;
  margin-bottom: 1.25rem;
`;

export const ArrowDown = styled.img`
  margin: 2rem 0;
  width: 100%;
`;
