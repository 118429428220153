import React, { useContext } from 'react';

import AppContext from '../../App/App.context';
import FooterLarge from './FooterLarge';
import FooterSmall from './FooterSmall';

function Footer() {
  const { currentDeviceSize } = useContext(AppContext);

  if (currentDeviceSize === 'large') {
    return <FooterLarge />;
  }

  return <FooterSmall />;
}

export default Footer;
