import axios from 'axios';

export const createDemande = data =>
  axios.post(process.env.REACT_APP_SAVE_API_URL, data, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });

export default { createDemande };
