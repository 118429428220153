import styled from 'styled-components';

import CarModel from '../CarModel';

export const SelectedCarModelsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 16rem;
`;

export const LargeCarModel = styled(CarModel)`
  img {
    max-height: 7.5rem;
    max-width: 100%;
    padding: 0;
  }

  p {
    display: none;
  }
`;

export const CarModelPlaceholder = styled(CarModel)`
  opacity: 0.75;

  img {
    max-height: 4.5rem;
    max-width: 7.5rem;
  }

  p {
    font-size: 0.75rem;
    margin: 0;
  }
`;
