import React, { useContext } from 'react';

import AppContext from '../../App/App.context';
import HeaderAutobiz from './HeaderAutobiz';
import HeaderLarge from './HeaderLarge';
import HeaderSmall from './HeaderSmall';

function Header(props) {
  const { currentDeviceSize } = useContext(AppContext);

  if (currentDeviceSize === 'large') {
    if (window.location.hostname === 'autobiz.reserverunessai.com') {
      return <HeaderAutobiz {...props} />;
    }

    return <HeaderLarge {...props} />;
  }

  return <HeaderSmall {...props} />;
}

export default Header;
