import styled from 'styled-components';

const LabelForm = styled.p`
  position: relative;

  &::after {
    color: ${({ theme }) => theme.colors.red};
    content: '*';
    left: calc(100% + 0.25rem);
    position: absolute;
    top: 0;
  }
`;

export default LabelForm;
