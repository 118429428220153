import styled from 'styled-components';
import { InputText } from 'timeone-components';
import media from 'styled-media-query';

import iconCheck from '../assets/icons/check.svg';

const SquarredInputText = styled(InputText)`
  &.tol-input-container {
    align-items: center;
    background-color: ${({ theme }) => theme.colors.white};
    border: 0.0625rem ${({ theme }) => theme.colors.primary} solid;
    display: flex;
    padding: 0.75rem 2.5rem 0.75rem 6rem;
    position: relative;
    ${media.greaterThan('large')`
      margin: 0.5rem 0 1.5rem;
      width: calc(50% - 9.5rem);
    `}
    ${media.lessThan('large')`
      margin: 0.5rem auto 1.5rem;
      width: calc(100% - 11rem);
    `}

    &::before,
    &::after {
      background-position: center;
      background-repeat: no-repeat;
      content: '';
      display: block;
      height: 1.75rem;
      position: absolute;
      width: 1.75rem;
    }

    &::before {
      background-image: url(${({ icon }) => icon});
      left: 2.25rem;
    }

    &::after {
      background-image: url(${({ valid }) => valid && iconCheck});
      right: 0.875rem;
    }

    &:focus {
      background-color: rgba(232, 232, 232, 0.4);
      outline: none;
    }

    .tol-input {
      margin: 0;

      .to-defaultinput__option-label {
        display: none;
      }

      & > span {
        font-size: 0.75rem;
        left: 0;
        text-transform: none;
        top: 0.125rem;

        &.active {
          visibility: hidden;
        }
      }

      input {
        border: none;
        font-size: 0.75rem;
        margin: 0;
        padding: 0;
      }

      &::before,
      &::after {
        content: none;
      }
    }

    & > span {
      left: 0.25rem;
      margin: 0;
      position: absolute;
      top: calc(100% + 0.25rem);
    }
  }
`;

export default SquarredInputText;
