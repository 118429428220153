import styled from 'styled-components';

export const HeaderLargeWrapper = styled.header`
  background-color: ${({ theme }) => theme.colors.primary};
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
`;

export const Logo = styled.img`
  left: 1rem;
  position: absolute;
  top: 1rem;
  width: 7rem;
`;

export const TitleContainer = styled.div`
  color: ${({ theme }) => theme.colors.white};
  margin: 1rem 0;
  text-align: center;
`;

export const Title = styled.h1`
  color: ${({ theme }) => theme.colors.white};
  font-size: 2rem;
  font-weight: 800;
  margin: 0;
`;

export const Subtitle = styled.h2`
  font-size: 1rem;
  font-weight: 500;
  margin: 0.25rem 0;
`;
