import styled from 'styled-components';

export const BrandWrapper = styled.button`
  cursor: pointer;
  height: 5rem;
  position: relative;
  text-align: center;
  width: 6.5rem;
`;

export const Logo = styled.img`
  margin: auto;
  max-height: 3.125rem;
  width: auto;
`;
