import { getBudgets } from './budget.api';

const getBudgetOptions = () =>
  getBudgets().then(res => {
    if (res.status === 200) {
      return res.data;
    }

    return [];
  });

export default { getBudgetOptions };
