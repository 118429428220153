import React, { useContext } from 'react';

import AppContext from '../../App/App.context';
import SelectionDesktop from './SelectionDesktop';
import SelectionMobile from './SelectionMobile';

function Selection(props) {
  const { currentDeviceSize } = useContext(AppContext);

  if (currentDeviceSize === 'large') {
    return <SelectionDesktop {...props} />;
  }

  return <SelectionMobile {...props} />;
}

export default Selection;
