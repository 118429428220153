import styled from 'styled-components';
import media from 'styled-media-query';
import { Button, Form as TocForm, InputSelect } from 'timeone-components';
import renderInputWithStyle from '../../utils/renderInputWithStyle';

export const Form = styled(TocForm)`
  ${media.greaterThan('large')`
    display: flex;
    justify-content: center;
  `}
  ${media.lessThan('large')`
    text-align: center;
  `}
  width: 100%;
`;

export const InputWrapperDealership = styled.div`
  ${media.greaterThan('large')`
    display: inline-block;
    max-height: 4.875rem;
    overflow: hidden;
    transition: all 0.25s;
    width: 0;

    &.visible {
      margin: 0 1rem;
      overflow: visible;
      width: 18.75rem;
    }
  `}
  ${media.lessThan('large')`
    max-height: 0;
    overflow: hidden;
    transition: all 0.25s;


    &.visible {
      overflow: visible;
      max-height: 6rem;
    }
  `}

  label span {
    white-space: nowrap;
  }

  & > p {
    font-size: 0.875rem;
    color: ${({ theme }) => theme.colors.error};
    white-space: nowrap;
  }
`;

export const Select = renderInputWithStyle(InputSelect);

export const ButtonSubmit = styled(Button)`
  background-color: ${({ theme }) => theme.colors.red};
  cursor: pointer;
  height: 2.75rem;
  ${media.greaterThan('large')`
    margin: 2rem 0 0;
  `}
  ${media.lessThan('large')`
    margin: 1rem auto 0;
  `}
  min-width: 13.125rem;

  &:disabled {
    background-color: ${({ theme }) => theme.colors.gray};
    cursor: default;
  }
`;

export const ButtonNextStep = styled.button`
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: 600;
  margin: 0.5rem auto 0.5rem;
  text-decoration: underline;
`;

export const Asterisk = styled.p`
  color: ${({ theme }) => theme.colors.error};
  display: block;
  font-size: 0.875rem !important;
  margin-top: 0.5rem;
  text-align: right;
`;
