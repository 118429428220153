import React, { useState } from 'react';
import { ThemeProvider } from 'styled-components';
import { generateMedia } from 'styled-media-query';

import { ErrorNotice, DesktopContent, MobileContentManager } from '../components';
import useMedia from '../hooks/useMedia';
import AppContext from './App.context';
import theme from '../theme';

const MEDIA_QUERIES = {
  large: '1024px',
  small: '640px',
};

const media = generateMedia(MEDIA_QUERIES);

function App() {
  const matchedMediaQuery = useMedia(Object.values(MEDIA_QUERIES), MEDIA_QUERIES.small);
  const currentDeviceSize = matchedMediaQuery === MEDIA_QUERIES.large ? 'large' : 'small';
  const [error, setError] = useState(null);
  const [userData, setUserData] = useState({
    budget: null,
    date: null,
    location: null,
    selectedCarModels: [],
  });

  if (error) {
    return <ErrorNotice />;
  }

  return (
    <ThemeProvider theme={{ ...theme, media }}>
      <AppContext.Provider
        value={{
          mediaQueries: MEDIA_QUERIES,
          currentDeviceSize,
          setError,
          userData,
          setUserData,
        }}
      >
        {currentDeviceSize === 'large' ? <DesktopContent /> : <MobileContentManager />}
      </AppContext.Provider>
    </ThemeProvider>
  );
}
export default App;
