import styled from 'styled-components';
import { assets } from 'timeone-components';

const { CheckIcon } = assets.icons;

export const BrandLargeWrapper = styled.button`
  background: ${({ theme }) => theme.colors.white};
  border: 0.0625rem solid ${({ theme }) => theme.colors.lightGray};
  border-left: none;
  border-right: none;
  cursor: pointer;
  height: 4rem;
  padding-left: 4rem;
  position: relative;
  text-align: center;
  width: 100%;
`;

export const Logo = styled.img`
  height: 3rem;
  left: 4rem;
  position: absolute;
  top: 0.5rem;
  width: 3rem;
`;

export const Name = styled.span`
  font-size: 0.875rem;
`;

export const IconChecked = styled(CheckIcon)`
  height: 1.5rem;
  opacity: 0.875;
  position: absolute;
  right: 2rem;
  top: 1.125rem;
  width: 1.5rem;
`;
