import styled, { css } from 'styled-components';
import media from 'styled-media-query';

export default function renderInputWithStyle(Input) {
  return styled(Input)`
    .to-defaultinput__option-label {
      display: none;
    }

    & > span:last-child {
      left: 0.25rem;
      ${media.greaterThan('large')(css`
        left: 0.25rem;
        top: calc(100% + 0.25rem);
      `)};
      ${media.lessThan('large')(css`
        left: 0.5rem;
        top: calc(100% + 0.125rem);
      `)};
    }
  `;
}
