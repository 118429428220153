import styled from 'styled-components';

export const ErrorNoticeWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  text-align: center;
`;

export const Details = styled.p`
  margin: 2rem 0;
`;

export const ButtonRetry = styled.button`
  font-size: 1rem;
  text-decoration: underline;
`;
