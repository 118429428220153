import React from 'react';

import { HeaderSmallWrapper, Logo } from './styles';
import { logo } from '../../../assets/images';

function HeaderSmall() {
  return (
    <HeaderSmallWrapper>
      <Logo src={logo} alt="logo r1e" />
    </HeaderSmallWrapper>
  );
}

export default HeaderSmall;
