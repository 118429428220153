import styled from 'styled-components';
import media from 'styled-media-query';

export const Label = styled.label`
  align-items: center;
  display: flex;
  position: relative;
  margin-right: 2rem;
  z-index: 1;
`;

export const Input = styled.input`
  cursor: pointer;
  height: 100%;
  left: 0;
  margin: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
`;

export const Control = styled.div`
  border-radius: 50%;
  border: solid ${({ theme }) => theme.colors.primary} 0.125rem;
  margin-right: 0.75rem;
  position: relative;
  z-index: -1;
  ${media.greaterThan('large')`
    height: 1.25rem;
    width: 1.25rem;
  `}
  ${media.lessThan('large')`
    height: 1rem;
    width: 1rem;
  `}

  &::after {
    background-color: transparent;
    border-radius: 50%;
    content: '';
    display: block;
    left: 0.125rem;
    position: absolute;
    top: 0.125rem;
    ${media.greaterThan('large')`
      height: 1rem;
      width: 1rem;
    `}
    ${media.lessThan('large')`
      height: 0.75rem;
      width: 0.75rem;
    `}
  }

  &.checked::after {
    background-color: ${({ theme }) => theme.colors.primary};
  }
`;
