import styled from 'styled-components';

export const FooterWrapper = styled.footer`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.primary};
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-height: 50%;
  padding-top: 5rem;
`;

export const ArrowFooter = styled.img`
  margin-bottom: -0.5rem;
  width: 100%;
`;

export const IconsContainer = styled.div`
  display: flex;
  text-align: center;
`;

export const Icon = styled.img`
  margin: 2.25rem 5.625rem 2.75rem;
  max-width: 5.25rem;
`;

export const IconCentered = styled(Icon)`
  margin-top: 0;
`;

export const IconLegend = styled.p`
  color: ${({ theme }) => theme.colors.white};
  font-size: 0.875rem;
  font-weight: 700;
`;

export const LegalMentionsContainer = styled.div`
  margin: 2.75rem 4rem 0;
  text-align: center;
  width: calc(100% - 8rem);
`;

export const LegalMention = styled.p`
  color: white;
  font-size: 0.75rem;
`;

export const LegalLink = styled.a`
  color: white;
  display: inline-block;
  font-size: 1rem;
  font-weight: bold;
  margin: 1rem 1rem 1.5rem;

  &:hover {
    text-decoration: underline;
  }
`;
