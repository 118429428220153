import styled from 'styled-components';
import media from 'styled-media-query';

import Backdrop from '../Backdrop';
import CarModelImage from '../CarModelImage';

export const CarModelWrapper = styled.div`
  cursor: pointer;
  height: 100%;
  justify-content: space-around;
  max-height: 7.5rem;
  min-width: 9.375rem;
  position: relative;
  text-align: center;
  transition: all 0.25s;
`;

export const Image = styled(CarModelImage)`
  padding-top: 0.5rem;
  max-height: 3.75rem;
  width: auto;
`;

export const Name = styled.p`
  font-size: 0.75rem;
  font-weight: 700;
  margin-top: 0.25rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const ButtonDelete = styled.button`
  position: absolute;
  ${media.greaterThan('large')`
    right: 0.5rem;
    top: 0.5rem;
  `}
  ${media.lessThan('large')`
    right: 0;
    top: 0;
  `}
`;

export const LightBackdrop = styled(Backdrop)`
  &.active {
    opacity: 0.5;
  }
`;

export const IconDelete = styled.img`
  height: 1.5rem;
  width: 1.5rem;
`;
