import styled from 'styled-components';

export const HeaderSmallWrapper = styled.header`
  background-color: ${({ theme }) => theme.colors.primary};
  display: flex;
  height: 5.25rem;
  justify-content: center;
  min-height: 5.25rem;
  position: relative;
  width: 100%;
`;

export const Logo = styled.img`
  display: block;
  height: 3.25rem;
  margin: 1rem;
`;
