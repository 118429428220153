import React from 'react';
import PropTypes from 'prop-types';

import AppContext from '../../App/App.context';
import { stepper } from '../../assets/images';
import { Dot, DotsContainer, MobileStepperWrapper, StepperImage, Title } from './styles';

function Stepper(props) {
  const { currentStep, steps } = props;
  const { title, description } = currentStep;

  return (
    <AppContext.Consumer>
      {({ currentDeviceSize }) =>
        currentDeviceSize === 'large' ? (
          <StepperImage src={stepper} alt="stepper" />
        ) : (
          <MobileStepperWrapper>
            <Title>{title}</Title>
            <p>{description}</p>
            <DotsContainer>
              {steps.map(({ name }) => (
                <Dot key={name} className={name === currentStep.name ? 'filled' : ''} />
              ))}
            </DotsContainer>
          </MobileStepperWrapper>
        )
      }
    </AppContext.Consumer>
  );
}

Stepper.defaultProps = {
  currentStep: {},
  steps: [{ name: '' }],
};

Stepper.propTypes = {
  currentStep: PropTypes.shape({}),
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
    })
  ),
};

export default Stepper;
