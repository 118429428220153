import React from 'react';

import { HeaderLargeWrapper, Logo, Subtitle, Title, TitleContainer } from './styledComponents';
import { logo } from '../../../assets/images';

function HeaderLarge() {
  return (
    <HeaderLargeWrapper>
      <Logo src={logo} alt="logo r1e" />
      <TitleContainer>
        <Title>VOTRE INVITATION VIP</Title>
        <Subtitle>Pour réserver un essai du véhicule neuf de votre choix</Subtitle>
      </TitleContainer>
    </HeaderLargeWrapper>
  );
}

export default HeaderLarge;
