import axios from 'axios';
import getZdd from '../../utils/getZdd';

export function getModels({ locationId, budget, dateId, isExcluRequalification }) {
  const zdd = getZdd();

  return axios(
    `${process.env.REACT_APP_MODELE_API_URL}/${zdd}/${locationId}/${budget}/${dateId}/${isExcluRequalification}`
  );
}

export function getModelsByBrand(brand) {
  return axios(`${process.env.REACT_APP_MODELE_API_URL}/getListByMarque/${brand}`);
}

export default { getModels, getModelsByBrand };
