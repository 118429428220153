import React, { PureComponent } from 'react';
import md5 from 'js-md5';

import AppContext from '../../App/App.context';
import facebookLogo from '../../assets/icons/facebook.svg';
import tagMail from '../../utils/tagMail';
import { ConfirmationWrapper, Paragraph } from './styles';

class Confirmation extends PureComponent {
  componentDidMount() {
    const { userData } = this.context;

    const emailMD5 = md5(userData.email);

    tagMail(emailMD5);
  }

  render() {
    const {
      userData: { firstname = '' },
    } = this.context;

    return (
      <ConfirmationWrapper>
        <Paragraph>
          Merci <b>{firstname}</b> !
        </Paragraph>
        <Paragraph>
          Votre demande a été <b>validée</b>. Vous serez rapidement recontacté par la concession qui confirmera avec
          vous le RDV. Au nom de toute l’équipe ReserverUnEssai.com je vous souhaite un bon essai.
        </Paragraph>
        <Paragraph>L’Equipe Reserverunessai.</Paragraph>
        <Paragraph>Nous rejoindre sur facebook :</Paragraph>
        <a href="https://www.facebook.com/Reserver1essai/" target="_blank" rel="noopener noreferrer">
          <img src={facebookLogo} alt="facebook logo" />
        </a>
      </ConfirmationWrapper>
    );
  }
}

Confirmation.contextType = AppContext;

export default Confirmation;
