import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import AppContext from '../../App/App.context';
import { SelectedCarModelsWrapper, LargeCarModel, CarModelPlaceholder } from './styles';
import { MAX_NUMBER_OF_SELECTABLE_CARMODELS, EXCLUSIVE_VALUE } from '../../constants';
import { isExclu } from '../../utils/zddConditions';

function SelectedCarModels({ brands }) {
  const addNumber = [isExclu() ? '' : '1er', '2ème', '3ème'];
  const carNumber = brands.length === 1 ? 1 : MAX_NUMBER_OF_SELECTABLE_CARMODELS;
  const { userData } = useContext(AppContext);
  const isExclusive = userData.selectedCarModels.some(
    ({ isExcluRequalification }) => isExcluRequalification === EXCLUSIVE_VALUE
  );

  return (
    <SelectedCarModelsWrapper>
      {new Array(isExclusive ? 1 : carNumber).fill().map((_, index) =>
        userData.selectedCarModels[index] ? (
          <>
            <LargeCarModel
              key={userData.selectedCarModels[index].idmodele}
              id={userData.selectedCarModels[index].idmodele}
              img={userData.selectedCarModels[index].img}
              deletable
            />
            {isExclusive ? <span>Pas d&apos;autre choix disponible avec ce modèle.</span> : null}
          </>
        ) : (
          <CarModelPlaceholder
            key={Math.random()}
            name={
              userData.selectedCarModels.length === index
                ? `Sélectionner un ${
                    addNumber[userData.selectedCarModels ? userData.selectedCarModels.length : 0]
                  } modèle`
                : ''
            }
          />
        )
      )}
    </SelectedCarModelsWrapper>
  );
}

SelectedCarModels.defaultProps = {
  brands: [],
};

SelectedCarModels.propTypes = {
  brands: PropTypes.arrayOf(PropTypes.shape({})),
};
export default SelectedCarModels;
