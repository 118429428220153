import React from 'react';
import PropTypes from 'prop-types';

import { BrandLargeWrapper, Logo, Name, IconChecked } from './styles';
import Backdrop from '../Backdrop';

function BrandLarge({ logo, name, onClick, selected, checked, disabled }) {
  return (
    <BrandLargeWrapper className={selected ? 'open' : ''} onClick={onClick}>
      <Backdrop className={disabled ? 'active' : ''} />
      <Logo src={logo} alt={name} />
      <Name>{name}</Name>
      {checked && <IconChecked />}
    </BrandLargeWrapper>
  );
}

BrandLarge.propTypes = {
  checked: PropTypes.bool.isRequired,
  logo: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default BrandLarge;
