import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import LabelForm from '../LabelForm';
import AppContext from '../../App/App.context';
import { ButtonNextStep, ButtonSubmit, Form, InputWrapperDealership, Asterisk, Select } from './styles';
import { validateSelectRequired } from '../../utils/validations';
import dealershipFormService from '../../services/dealershipForm';
import { MAX_NUMBER_OF_SELECTABLE_CARMODELS } from '../../constants';

function Dealership({ displayButtonNextStep, onButtonNextStepClick, onSubmit, selectedCarModel }) {
  const [dealerships, setDealerships] = useState([]);
  const { currentDeviceSize, userData, setUserData } = useContext(AppContext);
  const { selectedCarModels } = userData;

  const selectIsActive = Array.isArray(dealerships) && dealerships.length > 0 && selectedCarModel;
  const isCarModelAlreadySelected = selectedCarModels.some(
    model =>
      selectedCarModel && (model.idmodele === selectedCarModel.idmodele || model.marque === selectedCarModel.marque)
  );

  let resetSelect = () => {};

  useEffect(
    () => {
      resetSelect();

      if (selectedCarModel) {
        loadDealershipsOptions();
      }
    },
    [selectedCarModel]
  );

  useEffect(
    () => {
      if (dealerships === true && currentDeviceSize !== 'large') {
        handleSubmit({});
      }
    },
    [currentDeviceSize, dealerships]
  );

  async function loadDealershipsOptions() {
    const newDealerships = await dealershipFormService.getDealershipsOptionsFormatted(
      selectedCarModel.idannonceur,
      userData.location.id
    );

    setDealerships(newDealerships);
  }

  function handleSubmit({ dealership }) {
    if (selectedCarModels.length < MAX_NUMBER_OF_SELECTABLE_CARMODELS && !isCarModelAlreadySelected) {
      setUserData({
        ...userData,
        selectedCarModels: [...selectedCarModels, { ...selectedCarModel, dealership }],
      });
    }

    setDealerships([]);

    onSubmit();
  }

  return (
    <Form
      onSubmit={handleSubmit}
      renderButtons={({ invalid }) =>
        currentDeviceSize === 'large' && (
          <div>
            <ButtonSubmit
              type="submit"
              primary
              fill="true"
              disabled={Array.isArray(dealerships) && (invalid || !selectedCarModel || isCarModelAlreadySelected)}
            >
              VALIDER MA VOITURE
            </ButtonSubmit>
            {displayButtonNextStep && <ButtonNextStep onClick={onButtonNextStepClick}>Étape suivante</ButtonNextStep>}
          </div>
        )
      }
      render={({ form }) => {
        resetSelect = form.reset;

        return (
          <InputWrapperDealership className={selectIsActive ? 'visible' : ''}>
            {Array.isArray(dealerships) && (
              <Select
                config={{
                  isDisabled: !selectedCarModel,
                  menuPlacement: currentDeviceSize === 'large' ? 'top' : undefined,
                  maxMenuHeight: 256,
                }}
                label={<LabelForm>Choix de ma concession</LabelForm>}
                name="dealership"
                onChange={event => currentDeviceSize !== 'large' && handleSubmit({ dealership: event.target.value })}
                options={dealerships}
                required
                validation={selectIsActive ? validateSelectRequired : undefined}
              />
            )}
            <Asterisk>* champs obligatoires</Asterisk>
          </InputWrapperDealership>
        );
      }}
    />
  );
}

Dealership.defaultProps = {
  displayButtonNextStep: false,
  selectedCarModel: null,
  onButtonNextStepClick: () => {},
  onSubmit: () => {},
};

Dealership.propTypes = {
  displayButtonNextStep: PropTypes.bool,
  selectedCarModel: PropTypes.shape({}),
  onButtonNextStepClick: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default Dealership;
