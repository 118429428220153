import React from 'react';
import PropTypes from 'prop-types';

import { BrandWrapper, Logo } from './styles';
import Backdrop from '../Backdrop';

const Brand = ({ className, logo, name, onClick, disabled }) => (
  <BrandWrapper className={className} onClick={onClick}>
    <Backdrop className={disabled ? 'active' : ''} />
    <Logo src={logo} alt={name} />
  </BrandWrapper>
);

Brand.defaultProps = {
  className: '',
};

Brand.propTypes = {
  className: PropTypes.string,
  logo: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default Brand;
