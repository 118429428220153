import { isExclu } from './utils/zddConditions';

export const MAX_NUMBER_OF_SELECTABLE_CARMODELS = isExclu() ? 1 : 3;

export const SCHEDULES_SELECT_OPTIONS = [
  { label: '9h00', value: '09:00' },
  { label: '10h00', value: '10:00' },
  { label: '11h00', value: '11:00' },
  { label: '12h00', value: '12:00' },
  { label: '14h00', value: '14:00' },
  { label: '15h00', value: '15:00' },
  { label: '16h00', value: '16:00' },
  { label: '17h00', value: '17:00' },
  { label: '18h00', value: '18:00' },
];

export const EXCLUSIVE_VALUE = '1';

export const NOT_EXCLUSIVE_VALUE = '0';

export default { MAX_NUMBER_OF_SELECTABLE_CARMODELS, SCHEDULES_SELECT_OPTIONS };
