import { useContext } from 'react';

import AppContext from '../App/App.context';

export default function useDeleteSavedCarModel() {
  const { userData, setUserData } = useContext(AppContext);
  const { selectedCarModels } = userData;

  function deleteSavedCarModel(id) {
    const filteredSelectedCarModels = selectedCarModels.filter(({ idmodele }) => id !== idmodele);

    setUserData({ ...userData, selectedCarModels: filteredSelectedCarModels });
  }

  return deleteSavedCarModel;
}
