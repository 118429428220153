import React, { Component } from 'react';
import * as Scroll from 'react-scroll';

import AppContext from '../../App/App.context';
import { arrowDown } from '../../assets/images';
import Configuration from '../Configuration';
import Confirmation from '../Confirmation';
import Footer from '../Footer';
import Header from '../Header';
// import LogosCarousel from '../LogosCarousel';
import Selection from '../Selection';
import Stepper from '../Stepper';
import Validation from '../Validation';
import { ScrollAnchor, DesktopContentWrapper, ArrowDown, SectionTitle, SectionSubtitle } from './styles';
import iframe from '../../utils/iframe';
import { isExclu } from '../../utils/zddConditions';
import { MAX_NUMBER_OF_SELECTABLE_CARMODELS } from '../../constants';

const exclusivity = isExclu();

class DesktopContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      brands: null,
      shouldDisplayValidation: false,
      shouldDisplayConfirmation: false,
    };
  }

  componentDidUpdate = () => {
    const { shouldDisplayCarsSelection, shouldDisplayValidation } = this.state;
    const { userData, setUserData } = this.context;
    const { date, budget, location, selectedCarModels } = userData;

    if (shouldDisplayValidation === true && selectedCarModels.length === 0) {
      this.setState({
        shouldDisplayValidation: false,
      });
    }

    if (shouldDisplayValidation === false && selectedCarModels.length === MAX_NUMBER_OF_SELECTABLE_CARMODELS) {
      this.displayValidation();
    }

    if (shouldDisplayCarsSelection && (!date || !budget || !location)) {
      setUserData({ ...userData, selectedCarModels: [] });

      this.setState({
        brands: null,
        shouldDisplayValidation: false,
      });
    }
  };

  handleConfigurationSubmit = brands => {
    this.setState({ brands });

    return this.scrollTo('selection');
  };

  scrollTo = name => {
    const { scroller } = Scroll;

    scroller.scrollTo(name, { duration: 1500, delay: 100, smooth: true });
  };

  displayValidation = () => {
    this.setState({ shouldDisplayValidation: true });
    this.scrollTo('validation');
  };

  render() {
    const { brands, shouldDisplayConfirmation, shouldDisplayValidation } = this.state;
    const { userData } = this.context;
    const { selectedCarModels } = userData;

    if (shouldDisplayConfirmation) {
      return <Confirmation />;
    }

    return (
      <>
        {!iframe ? (
          <>
            <Header />
            {/* <LogosCarousel /> ohmycar a été desactivé, plus de data pour le carrousel */}
            <Stepper />
          </>
        ) : (
          ''
        )}
        <DesktopContentWrapper>
          <SectionTitle>Complétez vos informations</SectionTitle>
          <SectionSubtitle>Entrez votre code postal, votre budget et votre délai d&apos;achat.</SectionSubtitle>
          <Configuration onSubmit={this.handleConfigurationSubmit} />
          <ScrollAnchor name="selection">
            {brands && (
              <>
                <ArrowDown src={arrowDown} alt="down arrow" />
                <SectionTitle>CHOISISSEZ VOTRE MODÈLE</SectionTitle>
                {!exclusivity && (
                  <SectionSubtitle>
                    Sélectionnez jusqu&apos;à trois modèles disponibles pour réserver votre essai.
                  </SectionSubtitle>
                )}
                <Selection
                  selectedCarModels={selectedCarModels}
                  nextStep={() => this.displayValidation()}
                  brands={brands}
                  isValidationDisplayed={shouldDisplayValidation}
                />
              </>
            )}
          </ScrollAnchor>
          <ScrollAnchor name="validation">
            {shouldDisplayValidation && (
              <>
                <ArrowDown src={arrowDown} alt="down arrow" />
                <SectionTitle>Validez votre réservation</SectionTitle>
                <Validation onSubmit={() => this.setState({ shouldDisplayConfirmation: true })} />
              </>
            )}
          </ScrollAnchor>
        </DesktopContentWrapper>
        {!iframe ? <Footer /> : ''}
      </>
    );
  }
}

DesktopContent.contextType = AppContext;

export default DesktopContent;
