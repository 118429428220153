import brandsApi from './brands.api';

async function getBrandLogos() {
  try {
    const brandList = await brandsApi.getBrandLogos();

    return brandList.data;
  } catch (error) {
    console.error(error);
    return [{}];
  }
}

async function getBrandsFormatted(inputValue) {
  try {
    const { data } = await brandsApi.getBrands(inputValue);

    return data
      .map(
        ({ marque, idmarque }) =>
          marque.toLowerCase().includes(inputValue.toLowerCase()) && {
            label: marque,
            value: idmarque,
          }
      )
      .filter(Boolean);
  } catch (error) {
    console.error(error);
    return null;
  }
}

export default { getBrandLogos, getBrandsFormatted };
