import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { defaultModel } from '../assets/images';

function CarModelImage({ alt, className, src }) {
  const [shouldDisplayImageFallback, setShouldDisplayImageFallback] = useState(false);

  useEffect(() => setShouldDisplayImageFallback(false), [src]);

  return shouldDisplayImageFallback ? (
    <img className={className} src={defaultModel} alt="default car model" />
  ) : (
    <img className={className} src={src} alt={alt} onError={() => setShouldDisplayImageFallback(true)} />
  );
}

CarModelImage.defaultProps = {
  alt: 'default car model',
  className: '',
  src: defaultModel,
};

CarModelImage.propTypes = {
  alt: PropTypes.string,
  className: PropTypes.string,
  src: PropTypes.string,
};

export default CarModelImage;
