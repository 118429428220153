import styled from 'styled-components';

const Backdrop = styled.div`
  background-color: ${({ theme }) => theme.colors.paleWhite};
  bottom: 0;
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: all 0.25s;
  visibility: hidden;
  width: 100%;
  z-index: 1;

  &.active {
    max-height: 6.875rem;
    opacity: 0.875;
    visibility: visible;
    cursor: default;
  }
`;

export default Backdrop;
