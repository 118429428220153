import { getDealerships } from './dealershipForm.api';

const getDealershipsOptionsFormatted = async (advertiserId, location) => {
  const response = await getDealerships(advertiserId, location);

  if (response.status === 204) {
    return true;
  }

  if (response.status === 200) {
    const dealerships = response.data || [];

    return dealerships.map(dealership => ({
      label: `${Math.round(dealership.distance)}KM - ${dealership.adresse} - ${dealership.nom}`,
      value: dealership.iddistributeur,
    }));
  }

  return [];
};

export default { getDealershipsOptionsFormatted };
