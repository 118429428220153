import React from 'react';

import {
  ArrowFooter,
  FooterWrapper,
  IconsContainer,
  Icon,
  IconCentered,
  IconLegend,
  LegalMentionsContainer,
  LegalMention,
  LegalLink,
} from './styles';
import arrowFooter from '../../../assets/images/arrow_footer.png';
import awardIcon from '../../../assets/icons/award.svg';
import moneyIcon from '../../../assets/icons/money.svg';
import steeringWheelIcon from '../../../assets/icons/steering_wheel.svg';

const FooterLarge = () => (
  <>
    <ArrowFooter src={arrowFooter} alt="arrow_footer" />
    <FooterWrapper>
      <IconsContainer>
        <div>
          <Icon src={steeringWheelIcon} alt="steeringWheel" className="steeringWheelIcon" />
          <IconLegend>10 ans d&apos;expertise automobile</IconLegend>
        </div>
        <div>
          <IconCentered src={moneyIcon} alt="money" className="moneyIcon" />
          <IconLegend>Service 100% gratuit</IconLegend>
        </div>
        <div>
          <Icon src={awardIcon} alt="award" className="awardIcon" />
          <IconLegend>Comparez avant d&apos;acheter</IconLegend>
        </div>
      </IconsContainer>
      <LegalMentionsContainer>
        <LegalMention>
          *données obligatoires (en cas d’omission votre inscription ne peut être prise en compte)
        </LegalMention>
        <LegalMention>
          En vous inscrivant, vous déclarez accepter la Charte des données personnelles du présent site et ses
          Conditions générales d’utilisation.
        </LegalMention>
        <LegalMention>
          Identité du responsable du traitement : société TIMEONE PUBLISHING ayant son siège au 20-24 rue Jacques Ibert,
          92300 LEVALLOIS PERRET.
        </LegalMention>
        <LegalMention>
          {`Les informations collectées sont transmises à nos partenaires afin qu’ils vous recontactent concernant les
            prestations sélectionnées. En application de la Loi "Informatique & Libertés" du 6 janvier 1978 telle que
            modifiée par la Loi du 6 août 2004 et du Règlement Général sur la Protection des Données n° 2016/679 du 27
            avril 2016, entré en vigueur le 25 mai 2018, vous disposez d'un droit d'accès, de rectification, d'opposition
            et de suppression des données vous concernant, ainsi qu'un droit à la portabilité, à la limitation du
            traitement et à l'information, que vous pouvez mettre en œuvre en écrivant à l'adresse suivante: Programme
            Reserverunessai.com - TimeOne Publishing SAS 20-24 rue Jacques Ibert, 92300 Levallois Perret ou sur l'adresse
            op-pub@timeonegroup.com.`}
        </LegalMention>
        <LegalLink
          href="https://www.reserverunessai.com/mentions_legales.php"
          target="_blank"
          rel="noopener noreferrer"
        >
          Mentions légales
        </LegalLink>
        <LegalLink href="https://publishing.timeonegroup.com/cgv/" target="_blank" rel="noopener noreferrer">
          Conditions Générales de Ventes
        </LegalLink>
        <LegalLink href="https://www.reserverunessai.com/cgu.php" target="_blank" rel="noopener noreferrer">
          Conditions Générales des Utilisateurs
        </LegalLink>
        <LegalLink
          href="https://www.reserverunessai.com/charte_protection.php"
          target="_blank"
          rel="noopener noreferrer"
        >
          Charte des données personnelles
        </LegalLink>
      </LegalMentionsContainer>
    </FooterWrapper>
  </>
);

export default FooterLarge;
