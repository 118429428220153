import axios from 'axios';

function getBrandLogos() {
  return axios(process.env.REACT_APP_LOGOBANNER_API_URL);
}

function getBrands() {
  return axios(process.env.REACT_APP_BRANDCURRENTMODEL_API_URL);
}

export default { getBrandLogos, getBrands };
