import styled, { css } from 'styled-components';
import media from 'styled-media-query';
import { Form as TocForm, InputAutocomplete, InputSelect } from 'timeone-components';
import renderInputWithStyle from '../../utils/renderInputWithStyle';

export const Form = styled(TocForm)`
  ${media.greaterThan('large')(
    css`
      margin: 0 auto 2rem;
      width: 87.5%;

      & > div {
        display: flex;
        justify-content: space-between;
        width: 100%;

        .tol-input-container {
          text-align: left;
          width: 33%;
        }
      }
    `
  )}
  ${media.lessThan('large')(
    css`
      margin: 0 auto 1rem;
      width: calc(100% - 2rem);

      & > div {
        margin: 1rem 0 2rem;
        text-align: center;
        width: 100%;

        .tol-input-container {
          padding: 0.5rem 0;
          text-align: left;
        }
      }
    `
  )}
`;

export const Autocomplete = renderInputWithStyle(InputAutocomplete);

export const Select = renderInputWithStyle(InputSelect);

export const Asterisk = styled.p`
  color: ${({ theme }) => theme.colors.error};
  display: block;
  font-size: 0.875rem;
  margin: 0.5rem 6.25% 0.5rem auto;
  text-align: right;
`;

export const ErrorMessage = styled.p`
  color: ${({ theme }) => theme.colors.error};
  margin-bottom: 2.5rem;
  text-align: center;
`;
