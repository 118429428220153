/* eslint-disable react/no-unescaped-entities */
import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import moment from 'moment';

import AppContext from '../../App/App.context';
import iconCalendar from '../../assets/icons/calendar.svg';
import iconMail from '../../assets/icons/mail.svg';
import iconName from '../../assets/icons/name.svg';
import iconPhone from '../../assets/icons/phone.svg';
import {
  activesAnnonceursFieldsService,
  brandsService,
  modelService,
  demandeService,
  validationService,
} from '../../services';
import { validateEmptyValue, validateName, validateSelectRequired, validateWithApi } from '../../utils/validations';
import { EXCLUSIVE_VALUE, SCHEDULES_SELECT_OPTIONS } from '../../constants';
import InputRadio from '../InputRadio';
import LabelForm from '../LabelForm';
import SquarredInputDatePicker from '../SquarredInputDatePicker';
import SquarredInputText from '../SquarredInputText';
import SquarredInputSelect from '../SquarredInputSelect';
import SquarredInputAutocomplete from '../SquarredInputAutocomplete';
import {
  ButtonSubmitSmall,
  ButtonSubmitLarge,
  ButtonPrevious,
  Form,
  InputCheckBox,
  InputsContainer,
  RadioWrapper,
  selectStyle,
  selectYearStyle,
  InfoSpan,
  RgpdContainer,
} from './styles';
import useIsDayBlocked from './useIsDayBlocked';
import { displayZddL4Optin } from '../../utils/zddConditions';

const RenderMonthElement = ({ month, onMonthSelect, onYearSelect }) => (
  <div
    className="input-group"
    style={{ display: 'flex', justifyContent: 'center', fontSize: '1rem', marginTop: '-5px' }}
  >
    <span>
      <Select
        value={{ label: month.format('MMM'), value: month.month() }}
        onChange={option => {
          onMonthSelect(month, option.value);
        }}
        styles={selectStyle}
        options={moment.monthsShort().map((label, value) => ({ label, value }))}
      />
    </span>
    <span>
      <Select
        value={{ label: month.year(), value: month.year() }}
        onChange={option => {
          onYearSelect(month, option.value);
        }}
        styles={selectYearStyle}
        options={Array(100)
          .fill(0)
          .map((_, key) => ({ label: moment().year() - key, value: moment().year() - key }))}
      />
    </span>
  </div>
);

RenderMonthElement.propTypes = {
  month: PropTypes.shape({}).isRequired,
  onMonthSelect: PropTypes.func.isRequired,
  onYearSelect: PropTypes.func.isRequired,
};

function Validation({ onButtonPreviousClick, onSubmit }) {
  const { currentDeviceSize, userData, setUserData, setError } = useContext(AppContext);
  const [activesOptionnalFields, setActivesOptionnalFields] = useState([]);
  const isDayBlocked = useIsDayBlocked();
  const isExclusive = userData.selectedCarModels
    ? userData.selectedCarModels.some(({ isExcluRequalification }) => isExcluRequalification === EXCLUSIVE_VALUE)
    : null;

  async function loadActivesOptionnalFields() {
    const fields = await activesAnnonceursFieldsService.getActiveOptionnalFields(userData.selectedCarModels);

    setActivesOptionnalFields(fields);
  }

  useEffect(
    () => {
      loadActivesOptionnalFields();
    },
    [userData.selectedCarModels]
  );

  function isFieldValid(getFieldState) {
    const fieldState = getFieldState();

    if (fieldState) {
      return fieldState.valid;
    }

    return false;
  }

  // eslint-disable-next-line consistent-return
  async function handleSubmit(values) {
    const emailValidationError = await validateWithApi(
      values.email,
      validationService.validateEmail,
      '* Veuillez entrer un email valide'
    );
    const phoneValidationError = await validateWithApi(
      values.phone,
      validationService.validatePhoneNumber,
      '* Veuillez entrer un numéro valide'
    );

    if (emailValidationError || phoneValidationError) {
      return { email: emailValidationError, phone: phoneValidationError };
    }

    const data = { ...userData, ...values };

    try {
      const response = await demandeService.saveDemande(data);

      if (response.status === 201 || response.status === 202) {
        await setUserData(data);
        onSubmit();
      } else {
        setError('Error when validating.');
      }
    } catch (error) {
      console.error(error);
      setError('Error when validating.');
    }
  }

  return (
    <Form
      initialValues={{ ...userData }}
      onSubmit={handleSubmit}
      render={({ form, values }) => (
        <>
          {isExclusive ? (
            <InfoSpan>Entrez vos informations afin de réserver votre essai avec la voiture selectionnée.</InfoSpan>
          ) : (
            <InfoSpan>Entrez vos informations afin de réserver votre essai avec les voitures selectionnées.</InfoSpan>
          )}
          <RadioWrapper>
            <InputRadio name="civility" value="Mr" checked={values.civility === 'Mr'}>
              M.
            </InputRadio>
            <InputRadio name="civility" value="Mme" checked={values.civility === 'Mme'}>
              Mme.
            </InputRadio>
          </RadioWrapper>
          <InputsContainer>
            <SquarredInputText
              name="firstname"
              label="Votre prénom*"
              icon={iconName}
              valid={isFieldValid(() => form.getFieldState('firstname'))}
              validation={validateName}
              required
            />
            <SquarredInputText
              icon={iconName}
              label="Votre nom*"
              name="lastname"
              valid={isFieldValid(() => form.getFieldState('lastname'))}
              validation={validateName}
              required
            />
            {activesOptionnalFields.includes('adresse') && (
              <SquarredInputText
                icon={iconName}
                label="Votre adresse*"
                name="adresse"
                valid={isFieldValid(() => form.getFieldState('adresse'))}
                validation={validateEmptyValue}
                required
              />
            )}
            <SquarredInputText
              icon={iconMail}
              label="Votre email*"
              name="email"
              type="email"
              valid={isFieldValid(() => form.getFieldState('email'))}
              validation={validateEmptyValue}
              required
            />
            <SquarredInputText
              icon={iconPhone}
              label="Votre téléphone*"
              name="phone"
              valid={isFieldValid(() => form.getFieldState('phone'))}
              validation={validateEmptyValue}
              required
            />
            <SquarredInputDatePicker
              config={{
                isDayBlocked,
                renderMonthElement: RenderMonthElement,
              }}
              headerInitialTitle="Sélectionnez une date*"
              label="Date de rendez-vous souhaitée"
              name="dateRDV"
              valid={isFieldValid(() => form.getFieldState('dateRDV'))}
              validation={validateEmptyValue}
              required
            />
            <SquarredInputSelect
              config={{ maxMenuHeight: 240 }}
              icon={iconCalendar}
              label="Sélectionnez un horaire souhaité*"
              name="schedule"
              options={SCHEDULES_SELECT_OPTIONS}
              valid={isFieldValid(() => form.getFieldState('horaire'))}
              validation={val => validateSelectRequired(val, '* Veuillez sélectionner un horaire valide')}
            />
            {activesOptionnalFields.includes('idmodele_actuel') && (
              <>
                <InputCheckBox
                  name="userDoesntOwnACar"
                  label="Je ne possède pas de véhicule"
                  onChange={event => {
                    if (event.target.checked) {
                      form.batch(() => {
                        form.change('userCurrentCarBrand', null);
                        form.change('userCurrentCarModel', null);
                      });
                    }
                  }}
                />
                {!values.userDoesntOwnACar && (
                  <>
                    <SquarredInputAutocomplete
                      label={<LabelForm>Marque de votre modèle actuel</LabelForm>}
                      name="userCurrentCarBrand"
                      noOptionsMessage={() => 'saisissez une valeur pour faire une recherche'}
                      searchOptions={brandsService.getBrandsFormatted}
                      validation={validateSelectRequired}
                    />
                    {values.userCurrentCarBrand && (
                      <SquarredInputAutocomplete
                        name="userCurrentCarModel"
                        noOptionsMessage={() => 'saisissez une valeur pour faire une recherche'}
                        label={<LabelForm>Modèle actuel</LabelForm>}
                        searchOptions={inputValue =>
                          modelService.getModelsByBrandFormatted({
                            brand: values.userCurrentCarBrand.value,
                            filter: inputValue,
                          })
                        }
                        validation={validateSelectRequired}
                      />
                    )}
                  </>
                )}
              </>
            )}
            <RgpdContainer>
              <p>
                <small>
                  Les champs marqués d’un « * » sont obligatoires, votre demande ne pourra pas être traitée s’ils ne
                  sont pas complétés.
                </small>
              </p>
              <br />
              <p>
                <small>
                  TIMEONE LMT traite les données personnelles vous concernant afin de répondre à votre demande d’être
                  recontacté dans le cadre de la demande d'essai automobile formulée dans ce formulaire. Les données
                  personnelles vous concernant peuvent également être transmises aux Partenaires Annonceurs de TIMEONE
                  LMT à des fins de prospection commerciale. Pour en savoir plus sur la gestion de vos données
                  personnelles et pour exercer vos droits, reportez vous à la{' '}
                  <u>
                    <a
                      href="https://www.reserverunessai.com/legal/politique-de-confidentialite/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Politique de confidentialité
                    </a>
                  </u>{' '}
                  de TIMEONE LMT.
                </small>
              </p>
            </RgpdContainer>
            <InputCheckBox
              name="newsletterPartner"
              label="Je souhaite recevoir des offres commerciales (sms/email/téléphone) liées uniquement à cette demande de projet de la part des partenaires annonceurs de TIMEONE LMT."
            />
            <InputCheckBox
              name="newsletterBrand"
              label={
                isExclusive
                  ? "J'accepte de recevoir par téléphone/email/SMS les offres de la marque sélectionnée pour essai."
                  : "J'accepte de recevoir par téléphone/email/SMS les offres des marques sélectionnées pour essai."
              }
            />
            {displayZddL4Optin() ? (
              <InputCheckBox
                name="optinAssuranceAuto"
                label="J’accepte que les données me concernant soient transmises aux partenaires annonceurs de TIMEONE LMT afin d’être recontacté par téléphone/email/SMS pour des offres commerciales pouvant être différentes de celles liées à votre demande de projet."
              />
            ) : null}
            <p>
              <small>
                Pour en savoir plus, rendez-vous sur notre{' '}
                <u>
                  <a
                    href="https://www.reserverunessai.com/legal/politique-de-confidentialite/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Politique de confidentialité.
                  </a>
                </u>
              </small>
            </p>
          </InputsContainer>
        </>
      )}
      renderButtons={({ invalid, pristine, values, submitting, dirtySinceLastSubmit }) =>
        currentDeviceSize === 'large' ? (
          <ButtonSubmitSmall
            type="submit"
            primary
            fill
            disabled={(invalid && !dirtySinceLastSubmit) || pristine || submitting || !values.civility}
          >
            RESERVER MON ESSAI
          </ButtonSubmitSmall>
        ) : (
          <>
            <ButtonSubmitLarge
              type="submit"
              disabled={(invalid && !dirtySinceLastSubmit) || pristine || submitting || !values.civility}
            >
              Valider votre réservation
            </ButtonSubmitLarge>
            <ButtonPrevious
              type="button"
              onClick={() => {
                setUserData({ ...userData, ...values });
                onButtonPreviousClick();
              }}
            >
              Revenir à l'étape de sélection des modèles
            </ButtonPrevious>
          </>
        )
      }
    />
  );
}

Validation.defaultProps = {
  onButtonPreviousClick: () => {},
};

Validation.propTypes = {
  onButtonPreviousClick: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
};

export default Validation;
