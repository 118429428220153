import { getCheckPhone, getCheckMail } from './validation.api';

export async function validatePhoneNumber(value) {
  try {
    const response = await getCheckPhone(value);

    if (response && response.status === 'OK') {
      return true;
    }

    return false;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function validateEmail(value) {
  try {
    const response = await getCheckMail(value);

    if (response && response.status === 'OK') {
      return true;
    }

    return false;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export default { validatePhoneNumber, validateEmail };
