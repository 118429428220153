import React, { Component } from 'react';
import PropTypes from 'prop-types';

import AppContext from '../../App/App.context';
import { Asterisk, Autocomplete, ErrorMessage, Select, Form } from './styles';
import LabelForm from '../LabelForm';
import ButtonSubmit from '../ButtonSubmit';
import { budgetService, dateService, localisationService, modelService, urlParamsService } from '../../services';
import { validateSelectRequired } from '../../utils/validations';

class Configuration extends Component {
  constructor(props) {
    super(props);

    this.state = {
      budgetOptions: [],
      dateOptions: [],
    };

    this.initialValues = {};
  }

  componentDidMount() {
    this.loadLocation();
    this.getDateOptions();
    this.getBudgetOptions();
  }

  loadLocation = async () => {
    const { userData, setUserData } = this.context;

    const values = urlParamsService.getUrlParams();
    const { cp, civility, firstname, email, lastname, phone } = values;
    const newUserData = {
      ...userData,
      cp,
      civility,
      firstname,
      email,
      lastname,
      phone,
      utm_source: values.utm_source,
    };

    if (cp) {
      const location = await localisationService.getLocationFormatted(cp);

      if (location) {
        this.initialValues = { location };
        return setUserData({ ...newUserData, location: location.value });
      }
    }

    return setUserData(newUserData);
  };

  getDateOptions = () => {
    dateService
      .getDateOptionsFormatted()
      .then(dateOptions => this.setState({ dateOptions }))
      .catch(console.error);
  };

  getBudgetOptions = () => {
    budgetService
      .getBudgetOptions()
      .then(budgetOptions => this.setState({ budgetOptions }))
      .catch(console.error);
  };

  handleChange = label => async event => {
    const { currentDeviceSize, userData, setUserData } = this.context;
    const { value } = event.target.value;
    const newUserValues = { ...userData, [label]: value };
    const shouldTriggerSubmit =
      currentDeviceSize === 'large' && !!newUserValues.location && !!newUserValues.date && !!newUserValues.budget;

    await setUserData(newUserValues);

    if (shouldTriggerSubmit) {
      this.handleSubmit();
    }
  };

  handleSubmit = async () => {
    const { onSubmit } = this.props;
    const { userData } = this.context;
    const { location, budget, date } = userData;

    try {
      const brands = await modelService.getModelsByBrands({ locationId: location.id, budget, dateId: date.id });

      if (brands.length === 0) {
        return this.setState({ error: new Error("Aucun résultat n'est disponible selon les informations remplies.") });
      }

      this.setState({ error: null });
      return onSubmit(brands);
    } catch (error) {
      console.error(error);
      return this.setState({
        error: { ...error, message: 'La récupération des modèles à échoué.' },
      });
    }
  };

  renderButtons = ({ submitting, pristine, invalid }) => {
    const { currentDeviceSize } = this.context;

    if (currentDeviceSize !== 'large') {
      const isDisabled = submitting || pristine || invalid;

      return (
        <ButtonSubmit type="submit" disabled={isDisabled}>
          Choisir le(s) modèle(s) à essayer
        </ButtonSubmit>
      );
    }

    return null;
  };

  render() {
    const { error, budgetOptions, dateOptions } = this.state;
    const { currentDeviceSize } = this.context;

    return (
      <>
        <Form onSubmit={this.handleSubmit} initialValues={this.initialValues} renderButtons={this.renderButtons}>
          <Autocomplete
            label={<LabelForm>Lieu de mon RDV</LabelForm>}
            name="location"
            noOptionsMessage={() => 'saisissez une valeur pour faire une recherche'}
            onChange={this.handleChange('location')}
            searchOptions={localisationService.getLocationsFormatted}
            validation={val => validateSelectRequired(val, '* Veuillez entrer votre lieu de RDV')}
          />
          <Select
            config={{
              noOptionsMessage: () => 'chargement des options',
              onMenuOpen:
                (!dateOptions.length || (budgetOptions.length && dateOptions.length === 0)) && this.getDateOptions,
            }}
            label={<LabelForm>Date d&apos;acquisition souhaitée</LabelForm>}
            name="acquisitionDate"
            onChange={this.handleChange('date')}
            options={dateOptions}
            validation={val => validateSelectRequired(val, "* Veuillez entrer votre date d'acquisition")}
          />
          <Select
            config={{
              noOptionsMessage: () => 'chargement des options',
              onMenuOpen:
                (!budgetOptions.length || (budgetOptions.length && budgetOptions.length === 0)) &&
                this.getBudgetOptions,
            }}
            label={<LabelForm>Mon budget</LabelForm>}
            name="budget"
            onChange={this.handleChange('budget')}
            options={budgetOptions}
            validation={val => validateSelectRequired(val, '* Veuillez entrer votre budget')}
          />
        </Form>
        {currentDeviceSize === 'large' && <Asterisk>* champs obligatoires</Asterisk>}
        {error && <ErrorMessage>{error.message}</ErrorMessage>}
      </>
    );
  }
}

Configuration.contextType = AppContext;

Configuration.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default Configuration;
