import styled from 'styled-components';
import media from 'styled-media-query';

export const ConfirmationWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  margin: 0 auto;
  max-width: 48rem;
  padding: 0 2rem;
  text-align: center;
`;

export const Paragraph = styled.p`
  ${media.greaterThan('large')`
    margin: 2rem 0;
  `}
  ${media.lessThan('large')`
    margin: 1rem 0;
  `}
`;
