import { getActivesAnnonceursFields } from './activesAnnonceursFields.api';

function getActiveOptionnalFields(modelsValidated) {
  const modelsIdAnnonceur = modelsValidated.map(modelValidated => modelValidated.idannonceur);

  return getActivesAnnonceursFields().then(({ data }) =>
    modelsIdAnnonceur
      .map(modelIdAnnonceur => (data[modelIdAnnonceur] ? data[modelIdAnnonceur].map(field => field.field_mapping) : []))
      .reduce((reducer, fields) => [...reducer, ...fields], [])
  );
}

export default { getActiveOptionnalFields };
