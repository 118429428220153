import { useState, useEffect } from 'react';

function useMedia(mediaQueries, defaultValue) {
  const mediaQueryList = mediaQueries.map(query => window.matchMedia(`(min-width: ${query})`));
  const getValue = () => {
    const value = mediaQueryList.reduce((accumulator, { media, matches }) => {
      const width = /\d+/.exec(media)[0];

      if (matches && Number.parseInt(width, 10) > accumulator) {
        return width;
      }

      return accumulator;
    }, /\d+/.exec(defaultValue)[0]);

    return `${value}px`;
  };

  const [value, setValue] = useState(getValue);

  useEffect(() => {
    const handler = () => setValue(getValue);

    mediaQueryList.forEach(mediaQuery => mediaQuery.addListener(handler));

    return () => mediaQueryList.forEach(mediaQuery => mediaQuery.removeListener(handler));
  }, []);

  return value;
}

export default useMedia;
