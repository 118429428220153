import React from 'react';
import PropTypes from 'prop-types';

import { CarModelLargeWrapper, Logo, Image, InfoContainer, Marque, Modele, IconDelete } from './styles';
import deleteIcon from '../../assets/icons/delete.svg';
import { defaultModel } from '../../assets/images';
import useDeleteSavedCarModel from '../../hooks/useDeleteSavedCarModel';

function CarModelLarge({ logo, idmodele, img, marque, modele }) {
  const deleteSavedCarModel = useDeleteSavedCarModel();

  function handleClick() {
    deleteSavedCarModel(idmodele);
  }

  return (
    <CarModelLargeWrapper>
      <Logo src={logo} alt={marque} />
      <Image src={img} alt={modele} />
      <InfoContainer>
        <Marque>{marque}</Marque>
        <Modele>{modele}</Modele>
      </InfoContainer>
      <button type="button" onClick={handleClick}>
        <IconDelete src={deleteIcon} alt="delete car" />
      </button>
    </CarModelLargeWrapper>
  );
}

CarModelLarge.defaultProps = {
  idmodele: '',
  img: defaultModel,
  logo: '',
  marque: '',
  modele: '',
};

CarModelLarge.propTypes = {
  idmodele: PropTypes.string,
  img: PropTypes.string,
  logo: PropTypes.string,
  marque: PropTypes.string,
  modele: PropTypes.string,
};

export default CarModelLarge;
