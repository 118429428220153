export function getUrlParams() {
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);
  const userData = {};
  const keysMapping = {
    civ: 'civility',
    cp: 'cp',
    fname: 'firstname',
    mail: 'email',
    name: 'lastname',
    tel: 'phone',
    utm_source: 'utm_source',
  };

  params.forEach((value, key) => {
    if (key === 'civ') {
      if (value === '1') {
        userData[keysMapping[key]] = 'Mme';
      } else {
        userData[keysMapping[key]] = 'Mr';
      }
    } else {
      userData[keysMapping[key]] = value;
    }
  });

  return userData;
}

export default { getUrlParams };
