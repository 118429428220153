import { createDemande } from './demande.api';
import getZdd from '../../utils/getZdd';

function formatData(data) {
  return {
    id_collect: 'null',
    callcenter_id: '0',
    zdd: getZdd(),
    civilite: data.civility,
    nom: data.lastname,
    prenom: data.firstname,
    email: data.email,
    tel_mobile: data.phone,
    accept_newsletter2: data.newsletterPartner === true ? 1 : 0,
    optinAssuranceAuto: data.optinAssuranceAuto === true ? 1 : 0,
    code_postal: data.location.zipcode,
    budget_prevu: data.budget,
    date_achat_prevue: data.date.value,
    idmodele_actuel: data.userCurrentCarModel ? data.userCurrentCarModel.value : null,
    adresse: data.adresse ? data.adresse : null,
    source_id: data.utm_source || '',
    ville: data.location.id,
    plageHorraire: 'Indifferent',
    assDemandeEssais: formatCarModelTestRequestInfos(data),
    user_agent: navigator ? navigator.userAgent : null,
  };
}

function formatCarModelTestRequestInfos(data) {
  return data.selectedCarModels.map(model => ({
    date_rdv: data.dateRDV.format('YYYY-MM-DD'),
    heure: data.schedule.value,
    iddistributeur: model.dealership ? model.dealership.value : null,
    newsletter: data.newsletterBrand === true ? 1 : 0,
    idmodele: model.idmodele,
    idcampagne: model.idannonceur,
  }));
}

function saveDemande(values) {
  const formatted = formatData(values);

  return createDemande(formatted);
}
export default { saveDemande };
