import React from 'react';

import { ErrorNoticeWrapper, Details, ButtonRetry } from './styles';

const ErrorNotice = () => (
  <ErrorNoticeWrapper>
    <Details>Une erreur est survenue lors de la soumission du formulaire.</Details>
    <ButtonRetry type="button" onClick={() => window.location.reload()}>
      Cliquez ici pour ré-essayer
    </ButtonRetry>
  </ErrorNoticeWrapper>
);

export default ErrorNotice;
