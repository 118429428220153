import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import AppContext from '../../App/App.context';
import { BrandsContainer, CarModelsContainer, DisplayModel, FlexCenterContainer } from './styles';
import Backdrop from '../Backdrop';
import Brand from '../Brand';
import Dealership from '../Dealership';
import SelectedCarModels from '../SelectedCarModels';
import CarModel from '../CarModel';
import CarModelImage from '../CarModelImage';
import useComparativeState from '../../hooks/useComparativeState';
import { EXCLUSIVE_VALUE, MAX_NUMBER_OF_SELECTABLE_CARMODELS, NOT_EXCLUSIVE_VALUE } from '../../constants';

function SelectionDesktop({ nextStep, brands, isValidationDisplayed }) {
  const [brandSelected, setBrandSelected] = useComparativeState({});
  const [selectedCarModel, setSelectedCarModel] = useComparativeState(null);
  const { userData } = useContext(AppContext);
  const currentCarModels = brandSelected.modele;
  const isExclusive = userData.selectedCarModels.some(
    ({ isExcluRequalification }) => isExcluRequalification === EXCLUSIVE_VALUE
  );
  const isNotExclusive = userData.selectedCarModels.some(
    ({ isExcluRequalification }) => isExcluRequalification === NOT_EXCLUSIVE_VALUE
  );
  const isCurrentCarModelsExclusive = brandSelected.modele
    ? brandSelected.modele.some(({ isExcluRequalification }) => isExcluRequalification === EXCLUSIVE_VALUE)
    : null;

  function handleBrandClick(brand) {
    setBrandSelected(brand);

    setSelectedCarModel(null);
  }

  return (
    <>
      <BrandsContainer>
        {brands.map(brand => {
          const exclusiveBrands = brand.modele.some(
            ({ isExcluRequalification }) => isExcluRequalification === EXCLUSIVE_VALUE
          );

          return (
            <Brand
              className={`brand-logo ${!!brandSelected && brandSelected.idmarque === brand.idmarque ? 'selected' : ''}`}
              disabled={
                userData.selectedCarModels.some(({ marque }) => marque === brand.marque) ||
                userData.selectedCarModels.length === MAX_NUMBER_OF_SELECTABLE_CARMODELS ||
                isExclusive ||
                (isNotExclusive && exclusiveBrands)
              }
              key={brand.idmarque}
              logo={brand.logo}
              name={brand.marque}
              onClick={() =>
                !(userData.selectedCarModels.length === MAX_NUMBER_OF_SELECTABLE_CARMODELS) && handleBrandClick(brand)
              }
            />
          );
        })}
      </BrandsContainer>
      <CarModelsContainer
        className={currentCarModels ? 'open' : ''}
        modelsLength={currentCarModels && currentCarModels.length}
        blocked={
          currentCarModels && userData.selectedCarModels.some(({ marque }) => marque === currentCarModels[0].marque)
        }
      >
        {currentCarModels && (
          <>
            <Backdrop
              className={
                userData.selectedCarModels.some(({ marque }) => marque === currentCarModels[0].marque) ||
                isExclusive ||
                (isNotExclusive && isCurrentCarModelsExclusive)
                  ? 'active'
                  : ''
              }
            />
            {currentCarModels.map(modelData => (
              <CarModel
                className={`car-model${
                  !!selectedCarModel && selectedCarModel.idmodele === modelData.idmodele ? ' selected' : ''
                }`}
                img={modelData.img}
                key={modelData.idmodele}
                name={modelData.modele}
                onClick={() => setSelectedCarModel(modelData)}
              />
            ))}
          </>
        )}
      </CarModelsContainer>
      <FlexCenterContainer>
        <DisplayModel>
          <CarModelImage
            src={selectedCarModel ? selectedCarModel.img : undefined}
            alt={selectedCarModel ? selectedCarModel.modele : undefined}
          />
          <Dealership
            selectedCarModel={selectedCarModel}
            displayButtonNextStep={
              userData.selectedCarModels && userData.selectedCarModels.length !== 0 && isValidationDisplayed !== true
            }
            onButtonNextStepClick={nextStep}
          />
        </DisplayModel>
        <SelectedCarModels brands={brands} />
      </FlexCenterContainer>
    </>
  );
}

SelectionDesktop.defaultProps = {
  isValidationDisplayed: false,
};

SelectionDesktop.propTypes = {
  nextStep: PropTypes.func.isRequired,
  brands: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isValidationDisplayed: PropTypes.bool,
};

export default SelectionDesktop;
