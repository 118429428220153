import React from 'react';

import { FooterSmallWrapper } from './styles';

function FooterSmall() {
  return (
    <FooterSmallWrapper>
      <p>Reserver1essai - Tous droits réservés</p>
      <a href="https://www.reserverunessai.com/mentions_legales.php" target="_blank" rel="noopener noreferrer">
        Mentions légales
      </a>
      <a href="https://publishing.timeonegroup.com/cgv/" target="_blank" rel="noopener noreferrer">
        Conditions Générales de Ventes
      </a>
      <a href="https://www.reserverunessai.com/cgu.php" target="_blank" rel="noopener noreferrer">
        Conditions Générales des Utilisateurs
      </a>
      <a href="https://www.reserverunessai.com/charte_protection.php" target="_blank" rel="noopener noreferrer">
        Charte des données personnelles
      </a>
    </FooterSmallWrapper>
  );
}

export default FooterSmall;
