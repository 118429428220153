import styled from 'styled-components';
import { InputSelect } from 'timeone-components';
import media from 'styled-media-query';

const SquarredInputSelect = styled(InputSelect)`
  &.tol-input-container {
    align-items: center;
    background-color: ${({ theme }) => theme.colors.white};
    border: 0.0625rem ${({ theme }) => theme.colors.primary} solid;
    display: flex;
    padding: 0;
    position: relative;
    ${media.greaterThan('large')`
      margin: 0.5rem 0 1.5rem;
      width: calc(50% - 1rem);
    `}
    ${media.lessThan('large')`
      margin: 0.5rem auto 1.5rem;
      width: calc(100% - 2.5rem);
    `}

    &::before {
      background-image: url(${({ icon }) => icon});
      background-position: center;
      background-repeat: no-repeat;
      content: '';
      display: block;
      height: 1.25rem;
      left: 2.5rem;
      opacity: 0.75;
      position: absolute;
      width: 1.25rem;
    }

    &:focus {
      background-color: rgba(232, 232, 232, 0.4);
      outline: none;
    }

    .tol-input {
      margin: 0;

      .to-defaultinput__option-label {
        display: none;
      }

      & > span {
        font-size: 0.75rem;
        left: 6rem;
        text-transform: none;
        top: 0.75rem;

        &.active {
          visibility: hidden;
        }
      }

      .select__control {
        border: none;
        font-size: 0.75rem;
        margin: 0;
        padding: 0;

        .select__single-value {
          margin-left: 5.5rem;
        }
      }

      &::before,
      &::after {
        content: none;
      }
    }

    & > span {
      left: 0.25rem;
      margin: 0;
      position: absolute;
      top: calc(100% + 0.25rem);
    }
  }
`;

export default SquarredInputSelect;
