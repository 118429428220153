import styled, { css } from 'styled-components';
import media from 'styled-media-query';

import ButtonSubmit from '../ButtonSubmit';

export const RemainingChoices = styled.p`
  margin: 1rem 2rem;
  text-align: center;
`;

const displayRedBorderWhenSelected = css`
  position: relative;

  &::after {
    background: transparent;
    bottom: 0;
    content: '';
    height: 0.25rem;
    left: 0;
    position: absolute;
    transition: all 0.25s;
    width: 100%;
  }

  &.selected::after {
    background: ${({ theme }) => theme.colors.red};
  }
`;

export const BrandsContainer = styled.div`
  width: 100%;
  ${media.greaterThan('large')`
    border-bottom: 0.0625rem solid ${({ theme }) => theme.colors.lightGray};
    border-top: 0.0625rem solid ${({ theme }) => theme.colors.lightGray};
    display: flex;
    justify-content: center;
    margin-top: 3.125rem;

    & > .brand-logo {
      border-right: 0.0625rem solid ${({ theme }) => theme.colors.lightGray};

      &:nth-child(1) {
        border-left: 0.0625rem solid ${({ theme }) => theme.colors.lightGray};
      }

      ${displayRedBorderWhenSelected}
    }
  `}
  ${media.lessThan('large')`
    margin-top: 1rem;
  `}
`;

export const CarModelsContainer = styled.div`
  position: relative;
  transition: all 0.25s;
  ${media.greaterThan('large')`
    border-bottom: 0.0625rem solid ${({ theme }) => theme.colors.lightGray};
    height: 0;
    overflow: hidden;
    padding: 0 2rem;
    text-align: center;
    white-space: nowrap;
    width: calc(100% - 4rem);

    & > .car-model {
      display: inline-block;
      border-right: 0.0625rem solid ${({ theme }) => theme.colors.lightGray};

      &:nth-child(2) {
        /* le premier child étant la backdrop */
        border-left: 0.0625rem solid ${({ theme }) => theme.colors.lightGray};
      }

      ${displayRedBorderWhenSelected}
    }

    &.open {
      height: 6.875rem;
      overflow: ${({ blocked }) => (blocked ? 'hidden' : 'auto')};
      overflow-y: hidden;
      ${({ blocked }) => blocked && `white-space: normal;`}
    }
  `}
  ${media.lessThan('large')(css`
    width: 100%;

    & > .car-model {
      display: inline-block;
      margin-bottom: 0.25rem;
      min-width: unset;
      width: 33%;
    }

    &.open {
      padding: 0.5rem 0 1.25rem;
    }
  `)}
`;

export const FlexCenterContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 3rem auto;
`;

export const DisplayModel = styled.div`
  margin: 4rem 0 2.25rem;
  text-align: center;
  width: 46.875rem;

  img {
    height: 12.5rem;
  }
`;

export const ErrorMessage = styled.p`
  color: ${({ theme }) => theme.colors.error};
  margin: 1rem 0;
  text-align: center;
`;

export const ButtonNext = styled(ButtonSubmit)`
  margin: 1rem 0.75rem 0;
  width: calc(100% - 1.5rem);
`;

export const ButtonPrevious = styled.button`
  font-size: 1rem;
  margin-top: 1rem;
`;
