import getZdd from './getZdd';

export function isExclu() {
  return getZdd() === process.env.REACT_APP_EXCLU_ZDD;
}

export function displayZddL4Optin() {
  return getZdd() === process.env.REACT_APP_ZDD_L4;
}

export default { isExclu, displayZddL4Optin };
