const createScript = () => {
  const elementScript = document.createElement('script');

  elementScript.type = 'text/javascript';
  return elementScript;
};

const insertScriptElementInDocument = elementScript => {
  const firstDocumentBodyScript = document.getElementsByTagName('script')[0];

  firstDocumentBodyScript.parentNode.insertBefore(elementScript, firstDocumentBodyScript);
};

const tagMail = emailMD5 => {
  const tag = createScript();

  tag.async = true;
  tag.src = `${document.location.protocol === 'https:' ? 'https' : 'http'}://aalkabot.com/p/tip/`;
  insertScriptElementInDocument(tag);

  const tipContainer = createScript(emailMD5);

  tipContainer.innerHTML = `
    var tip = tip || [];
    tip.push(['_setLead', '4589', '669044', '8994ecb341595ec22627f92e42258f88', '${emailMD5}', '']);
    `;
  insertScriptElementInDocument(tipContainer);
};

export default tagMail;
