import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import { Form as TocForm, Button, InputCheckBox as TocInputCheckBox } from 'timeone-components';

import ButtonSubmit from '../ButtonSubmit';

export const RenderMonthElementContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const selectStyle = {
  control: base => ({
    ...base,
    background: 'none',
    border: 'none',
    borderRadius: 0,
    boxShadow: 'none',
    minWidth: '5.5rem',
    minHeight: '2rem',
    transition: 'all 0.5s ease',
    ':hover': {
      cursor: 'pointer',
    },
  }),
  placeholder: base => ({
    ...base,
    textTransform: 'capitalize',
  }),
  valueContainer: base => ({
    ...base,
  }),
  menu: base => ({
    ...base,
    borderRadius: 2,
    boxShadow: '0 8px 10px 1px rgba(0,0,0,0.14), 0 3px 14px 2px rgba(0,0,0,0.12), 0 5px 5px -3px rgba(0,0,0,0.20)',
    padding: 0,
    marginTop: '1.5625rem',
    height: '11.25rem',
    '& > div': {
      height: '11.25rem',
    },
  }),
  option: (base, state) => ({
    ...base,
    borderBottom: '0.0625rem solid rgba(0, 0, 0, 0.1)',
    padding: '0.75rem 1.25rem',
    cursor: state.isFocused ? 'pointer' : 'inherite',
    color: state.isSelected ? 'rgba(0, 0, 0, 0.25)' : 'rgb(67, 75, 84)',
    ':active': {
      backgroundColor: 'rgba(0, 0, 0, 0.05)',
    },
  }),
  dropdownIndicator: base => ({
    ...base,
    padding: '0.125rem',
  }),
};

export const selectYearStyle = {
  ...selectStyle,
  control: base => ({
    ...base,
    background: 'none',
    border: 'none',
    borderRadius: 0,
    boxShadow: 'none',
    minWidth: '5rem',
    minHeight: '2rem',
    transition: 'all 0.5s ease',
    ':hover': {
      cursor: 'pointer',
    },
  }),
};

export const Form = styled(TocForm)`
  margin: 2rem auto;
  max-width: 56rem;
`;

export const RadioWrapper = styled.div`
  display: flex;
  ${media.greaterThan('large')`
    margin: 2rem 0 0.625rem;
  `}
  ${media.lessThan('large')`
    justify-content: center;
    margin: 0 0 0.5rem 2rem;
  `}
`;

export const InputsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 2rem 0 0;
`;

export const InputCheckBox = styled(props => (
  <div className={props.className}>
    <TocInputCheckBox {...props} />
  </div>
))`
  text-align: left;
  ${media.greaterThan('large')`
    width: 100%;
  `}
  ${media.lessThan('large')`
    margin: 1rem 0.5rem 0;
    width: calc(100% - 1rem);
  `}

  label {
    ${media.lessThan('large')`
      align-items: flex-start;
    `}

    span {
      font-size: 0.875rem;
      line-height: 1rem;
      margin-left: 1.5rem;
    }

    .input-check-mark {
      min-width: 1rem;
    }
  }
`;

export const ButtonSubmitSmall = styled(Button)`
  background-color: ${({ theme }) => theme.colors.red};
  border: none;
  margin: 4rem auto 0;
  padding: 1rem;

  &:hover {
    background-color: ${({ theme }) => theme.colors.red};
    box-shadow: ${({ theme }) => theme.shadows.dp4};
  }

  &:disabled {
    background-color: ${({ theme }) => theme.colors.gray};
    box-shadow: none;
  }
`;

export const ButtonSubmitLarge = styled(ButtonSubmit)`
  margin: 1.5rem auto 0;
  width: calc(100% - 2rem);
`;

export const ButtonPrevious = styled.button`
  font-size: 0.875rem;
  margin: 1.5rem 0 0;
  text-align: center;
  width: 100%;
`;

export const InfoSpan = styled.span`
  width: 18rem;
  display: block;
  margin: auto auto 1rem auto;
  text-align: center;
`;

export const RgpdContainer = styled.div`
  margin-bottom: 1rem;
`;
