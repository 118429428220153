import React from 'react';
import PropTypes from 'prop-types';

import { LightBackdrop, ButtonDelete, CarModelWrapper, Name, Image, IconDelete } from './styles';
import deleteIcon from '../../assets/icons/delete.svg';
import { defaultModel } from '../../assets/images';
import useDeleteSavedCarModel from '../../hooks/useDeleteSavedCarModel';

function CarModel({ className, disabled, id, img, name, onClick, deletable }) {
  const deleteSavedCarModel = useDeleteSavedCarModel();

  return (
    <CarModelWrapper className={className} onClick={deletable ? () => deleteSavedCarModel(id) : onClick}>
      <LightBackdrop className={disabled ? 'active' : ''} />
      <Image src={img} alt={name} />
      <Name>{name}</Name>
      {deletable && (
        <ButtonDelete type="button" onClick={() => deleteSavedCarModel(id)}>
          <IconDelete src={deleteIcon} alt="delete car" />
        </ButtonDelete>
      )}
    </CarModelWrapper>
  );
}

CarModel.defaultProps = {
  className: '',
  deletable: false,
  disabled: false,
  id: '',
  img: defaultModel,
  name: '',
  onClick: () => {},
};

CarModel.propTypes = {
  className: PropTypes.string,
  deletable: PropTypes.bool,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  img: PropTypes.string,
  name: PropTypes.string,
  onClick: PropTypes.func,
};

export default CarModel;
