import { themes } from 'timeone-components';

const { timeone } = themes;

const colors = {
  ...timeone.colors,
  lightGray: '#d5d5d7',
  paleWhite: '#edeff3',
  primary: '#32363f',
  red: '#dd1e20',
};

export default {
  ...timeone,
  colors,
};
