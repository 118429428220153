import { useState, useEffect } from 'react';
import moment from 'moment';

import holidaysService from '../../services/holidays';

function isDayBlocked(day, holidays) {
  const sundayMomentIndex = 0;
  const limitDate = moment().add(60, 'days');

  if (moment(day).isBefore(moment().add(6, 'days'))) {
    return true;
  }

  if (moment(day).day() === sundayMomentIndex) {
    return true;
  }

  if (moment(day).isAfter(limitDate)) {
    return true;
  }

  if (holidays.includes(moment(day).format('DD/MM'))) {
    return true;
  }

  return false;
}

function useIsDayBlocked() {
  const [holidays, setHolidays] = useState(null);

  useEffect(() => {
    holidaysService
      .get()
      .then(setHolidays)
      .catch(() => setHolidays([]));
  }, []);

  return day => isDayBlocked(day, holidays);
}

export default useIsDayBlocked;
