import { getDates } from './date.api';

const getDateOptionsFormatted = () =>
  getDates().then(res => {
    if (res.status === 200) {
      return res.data.map(date => ({
        label: date.label,
        value: date,
      }));
    }

    return [];
  });

export default { getDateOptionsFormatted };
