import styled from 'styled-components';

export const HeaderLargeWrapper = styled.header`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  justify-content: center;
  padding: 1rem;
  position: relative;
  width: 100%;
`;

const Logo = styled.img`
  object-fit: contain;
  width: 20rem;
`;

export const LogoAutobiz = styled(Logo)`
  height: 8rem;
  object-position: right;
`;

export const TitleContainer = styled.div`
  font-size: 1.25rem;
  font-weight: 600;
  margin: 2rem 1rem 0;
  text-align: center;
`;

export const LogoR1e = styled(Logo)`
  height: 4.5rem;
  object-position: left;
  transform: translateY(0.25rem);
`;
