import styled from 'styled-components';
import { transparentize } from 'polished';

import CarModelImage from '../CarModelImage';

export const CarModelLargeWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  height: 5rem;
  padding: 0 1.5rem;
  position: relative;
  transition: all 0.25s;
  width: calc(100% - 3rem);

  &:focus,
  &:active {
    background: ${({ theme }) => transparentize(0.875, theme.colors.gray)};
  }
`;

export const Logo = styled.div`
  background-image: url(${({ src }) => src});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: calc(100% - 2.5rem);
  margin: 1.25rem 0.25rem;
  width: 4rem;
`;

export const Image = styled(CarModelImage)`
  margin: 0 0.5rem;
  max-height: 3.75rem;
  width: auto;
`;

export const InfoContainer = styled.div`
  margin: 0 auto 0 0.5rem;
`;

const Paragraph = styled.p`
  font-size: 0.875rem;
  max-width: 8rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const Marque = styled(Paragraph)`
  margin-top: 0.125rem;
`;

export const Modele = styled(Paragraph)`
  font-weight: 700;
`;

export const IconDelete = styled.img`
  height: 1.5rem;
  width: 1.5rem;
`;
