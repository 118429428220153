import styled from 'styled-components';

export const StepperImage = styled.img`
  width: 100%;
`;

export const MobileStepperWrapper = styled.div`
  text-align: center;
  padding: 0.25rem 1.5rem 0;
  width: calc(100% - 3rem);
`;

export const Title = styled.h4`
  margin: 1rem 0 0.25rem;
`;

export const DotsContainer = styled.div`
  display: flex;
  margin: 1rem 0;
  justify-content: center;
  width: 100%;
`;

export const Dot = styled.div`
  border: 0.0625rem solid ${({ theme }) => theme.colors.primary};
  border-radius: 50%;
  height: 0.5rem;
  margin: 0 1rem;
  position: relative;
  width: 0.5rem;

  &.filled {
    background-color: ${({ theme }) => theme.colors.primary};
  }
`;
