import arrowDown from './arrow_down.png';
import arrowFooter from './arrow_footer.png';
import autobizLogo from './Autobiz_logo.png';
import button from './button.png';
import carRed from './car_red.jpg';
import carWhite from './car_white.jpg';
import defaultModel from './default_model.png';
import logo from './logo.png';
import mobileBanner from './mobile_banner.jpg';
import stepperRed from './stepper.png';
import stepperBlue from './stepper_blue.png';

const stepper = window.location.hostname === 'autobiz.reserverunessai.com' ? stepperBlue : stepperRed;

export { arrowDown, arrowFooter, autobizLogo, carRed, carWhite, button, defaultModel, logo, mobileBanner, stepper };
