import 'moment/locale/fr';
import { InputDatePicker } from 'timeone-components';
import styled from 'styled-components';
import media from 'styled-media-query';

const SquarredInputDatePicker = styled(InputDatePicker)`
  &.tol-input-container {
    background-color: ${({ theme }) => theme.colors.white};
    border: 0.0625rem ${({ theme }) => theme.colors.primary} solid;
    position: relative;
    ${media.greaterThan('large')`
      margin: 0.5rem 0 1.5rem;
      width: calc(50% - 1rem);
    `}
    ${media.lessThan('large')`
      margin: 0.5rem auto 1.5rem;
      width: calc(100% - 2.5rem);
    `}

    button {
      padding: 0;
      margin: 0;
    }

    .tol-input {
      margin: 0;

      .to-defaultinput__option-label {
        display: none;
      }

      & > span {
        font-size: 0.75rem;
        left: 6rem;
        text-transform: none;
        top: 0.75rem;

        &.active {
          visibility: hidden;
        }
      }

      .tol-input__datepicker {
        padding: 0;

        .SingleDatePickerInput {
          border: none;
          display: flex;
          flex-direction: row-reverse;
          position: relative;

          .SingleDatePickerInput_calendarIcon {
            height: 2.25rem;
            margin: auto 0.5rem auto 2rem;
            padding: 0.5rem;
            width: 2.25rem;

            svg {
              height: 1.25rem;
              width: 1.25rem;

              path {
                fill: ${({ theme }) => theme.colors.primary};
              }
            }
          }

          .DateInput_input {
            font-size: 0.75rem;
            margin-left: 1.25rem;
            padding: 0.5rem 0;
            width: calc(100% - 4.25rem);

            &.DateInput_input-focused {
              border: none;
            }
          }

          .SingleDatePickerInput_clearDate {
            height: 2.25rem;
            padding: 0.5rem;
            position: absolute;
            right: 0.875rem;
            top: 0.125rem;
            width: 2.25rem;

            .SingleDatePickerInput_clearDate__default:hover {
              background: none;
            }

            svg {
              height: 12px;
              width: 12px;
            }
          }
        }
      }

      &::before,
      &::after {
        content: none;
      }
    }

    .DayPicker__header__date {
      display: none;
    }

    .DayPicker__header__year {
      display: none;
    }

    & > span {
      left: 0.25rem;
      margin: 0;
      position: absolute;
      top: calc(100% + 0.25rem);
    }
  }
`;

export default SquarredInputDatePicker;
