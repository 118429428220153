import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { Element, scroller } from 'react-scroll';

import AppContext from '../../App/App.context';
import {
  RemainingChoices,
  BrandsContainer,
  CarModelsContainer,
  ErrorMessage,
  ButtonNext,
  ButtonPrevious,
} from './styles';
import BrandLarge from '../BrandLarge';
import Dealership from '../Dealership';
import CarModel from '../CarModel';
import CarModelLarge from '../CarModelLarge';
import { MAX_NUMBER_OF_SELECTABLE_CARMODELS, EXCLUSIVE_VALUE, NOT_EXCLUSIVE_VALUE } from '../../constants';
import useComparativeState from '../../hooks/useComparativeState';

function SelectionMobile({ brands, onButtonNextClick }) {
  const [brandSelected, setBrandSelected] = useComparativeState({});
  const [selectedCarModel, setSelectedCarModel] = useComparativeState(null);
  const [error, setError] = useState(null);
  const [validating, setValidating] = useState(false);
  const { userData } = useContext(AppContext);
  const { selectedCarModels } = userData;
  const currentCarModels = brandSelected.modele;
  const isExclusive = userData.selectedCarModels.some(
    ({ isExcluRequalification }) => isExcluRequalification === EXCLUSIVE_VALUE
  );
  const isNotExclusive = userData.selectedCarModels.some(
    ({ isExcluRequalification }) => isExcluRequalification === NOT_EXCLUSIVE_VALUE
  );
  const isCurrentCarModelsExclusive = brandSelected.modele
    ? brandSelected.modele.some(({ isExcluRequalification }) => isExcluRequalification === EXCLUSIVE_VALUE)
    : null;

  useEffect(
    () => {
      if (error && selectedCarModels.length !== MAX_NUMBER_OF_SELECTABLE_CARMODELS) {
        setError(null);
      }

      if (selectedCarModels.length === 0) {
        setValidating(false);
      }
    },
    [userData]
  );

  useEffect(
    () => {
      if (selectedCarModel) {
        scroller.scrollTo('dealership', { duration: 1500, delay: 100, smooth: true });
      }
    },
    [selectedCarModel]
  );

  function handleBrandClick(brand) {
    setBrandSelected(brand);

    setSelectedCarModel(null);
  }

  const isCarModelSaved = modelData => selectedCarModels.some(({ idmodele }) => idmodele === modelData.idmodele);

  const isCarModelSelected = modelData => isCarModelSaved(modelData) || modelData === selectedCarModel;

  const isCarModelDisabled = modelData => {
    const hasAnAlreadySelectedBrand = selectedCarModels.some(({ marque }) => marque === modelData.marque);

    if (selectedCarModels.length === MAX_NUMBER_OF_SELECTABLE_CARMODELS) {
      return !isCarModelSaved(modelData);
    }

    if (selectedCarModels.length > 0 && hasAnAlreadySelectedBrand) {
      return !isCarModelSelected(modelData);
    }

    if (isExclusive || (isNotExclusive && isCurrentCarModelsExclusive)) {
      return true;
    }

    return selectedCarModel && !isCarModelSelected(modelData);
  };

  function handleCarModeleClick(carModel) {
    if (
      !(
        selectedCarModels.length === MAX_NUMBER_OF_SELECTABLE_CARMODELS ||
        isExclusive ||
        (isNotExclusive && isCurrentCarModelsExclusive)
      )
    ) {
      setSelectedCarModel(carModel);
    }
  }

  const displayRemainingChoice =
    (selectedCarModels.length < MAX_NUMBER_OF_SELECTABLE_CARMODELS && brands.length > 1) || isNotExclusive;

  return (
    <>
      {validating ? (
        <>
          <div>
            {selectedCarModels.map(carModel => (
              <CarModelLarge key={carModel.idmodele} {...carModel} />
            ))}
          </div>
          {displayRemainingChoice && (
            <RemainingChoices>
              Vous pouvez encore sélectionner
              <b> {MAX_NUMBER_OF_SELECTABLE_CARMODELS - selectedCarModels.length} modèles </b>à essayer si vous le
              souhaitez.
            </RemainingChoices>
          )}
        </>
      ) : (
        <BrandsContainer>
          {brands.map(brand => {
            const exclusiveBrands = brand.modele.some(
              ({ isExcluRequalification }) => isExcluRequalification === EXCLUSIVE_VALUE
            );
            const alreadySelectedMarque = selectedCarModels.some(({ marque }) => marque === brand.marque);

            return (
              <>
                <BrandLarge
                  checked={alreadySelectedMarque}
                  key={brand.idmarque}
                  logo={brand.logo}
                  name={brand.marque}
                  disabled={
                    alreadySelectedMarque ||
                    userData.selectedCarModels.length === MAX_NUMBER_OF_SELECTABLE_CARMODELS ||
                    isExclusive ||
                    (isNotExclusive && exclusiveBrands)
                  }
                  onClick={() => handleBrandClick(brand)}
                  selected={!!brandSelected && brandSelected.idmarque === brand.idmarque}
                />
                {brandSelected === brand && (
                  <>
                    <CarModelsContainer className={brandSelected === brand ? 'open' : ''}>
                      {currentCarModels &&
                        currentCarModels.map(modelData => (
                          <CarModel
                            className="car-model"
                            disabled={isCarModelDisabled(modelData)}
                            deletable={isCarModelSelected(modelData)}
                            id={modelData.idmodele}
                            img={modelData.img}
                            key={modelData.idmodele}
                            name={modelData.modele}
                            onClick={() => handleCarModeleClick(modelData)}
                          />
                        ))}
                      {error && <ErrorMessage>{error}</ErrorMessage>}
                      <Element name="dealership">
                        <Dealership selectedCarModel={selectedCarModel} onSubmit={() => handleBrandClick({})} />
                      </Element>
                    </CarModelsContainer>
                  </>
                )}
              </>
            );
          })}
        </BrandsContainer>
      )}
      <ButtonNext
        disabled={selectedCarModels.length === 0}
        onClick={validating ? onButtonNextClick : () => setValidating(true)}
      >
        {isExclusive ? 'Validez votre sélection de modèle' : 'Validez votre sélection de modèles'}
      </ButtonNext>
      {validating && selectedCarModels.length < MAX_NUMBER_OF_SELECTABLE_CARMODELS && brands.length > 1 && (
        <ButtonPrevious type="button" onClick={() => setValidating(false)}>
          {isExclusive
            ? "Pas d'autre choix disponible avec ce modèle."
            : 'Sélectionnez jusqu’à 3 modèles disponibles pour les essayer près de chez vous.'}
        </ButtonPrevious>
      )}
    </>
  );
}

SelectionMobile.propTypes = {
  onButtonNextClick: PropTypes.func.isRequired,
  brands: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default SelectionMobile;
