import { useState } from 'react';

export default function useComparativeState(initialValue) {
  const [state, setState] = useState(initialValue);

  function updateState(newState) {
    if (state === newState) {
      setState(initialValue);
    } else {
      setState(newState);
    }
  }

  return [state, updateState];
}
