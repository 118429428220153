import styled from 'styled-components';

const ButtonSubmit = styled.button`
  background: ${({ theme }) => theme.colors.green};
  border-radius: 0.125rem;
  color: ${({ theme }) => theme.colors.white};
  display: block;
  font-size: 1rem;
  font-weight: 300;
  margin: 0.25rem auto;
  max-width: 100%;
  padding: 1rem 2.75rem;
  transition: all 0.25s;
  width: 100%;

  &:disabled {
    background: ${({ theme }) => theme.colors.gray};
  }
`;

export default ButtonSubmit;
