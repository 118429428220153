import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';

import { Control, Input, Label } from './styles';

function InputRadio({ value, children, checked }) {
  return (
    <Field name="civility" type="radio" value={value}>
      {({ input }) => (
        <Label htmlFor="civility">
          <Control className={checked ? 'checked' : ''} />
          <Input name="civility" type="radio" {...input} />
          <span>{children}</span>
        </Label>
      )}
    </Field>
  );
}
InputRadio.propTypes = {
  value: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
};

export default InputRadio;
