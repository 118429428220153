import React from 'react';

import { HeaderLargeWrapper, LogoAutobiz, LogoR1e, TitleContainer } from './styledComponents';
import { autobizLogo, logo } from '../../../assets/images';

function HeaderLarge() {
  return (
    <HeaderLargeWrapper>
      <LogoAutobiz src={autobizLogo} alt="autobiz" />
      <TitleContainer>vous invite à profiter des services de</TitleContainer>
      <LogoR1e src={logo} alt="logo r1e" />
    </HeaderLargeWrapper>
  );
}

export default HeaderLarge;
