import React from 'react';
import moment from 'moment';

const NAME_VALIDATION_PATTERN = /^[a-zA-Zàâçéèêëîïôûùüÿñæœ]+(([',. -][a-zA-Zàâçéèêëîïôûùüÿñæœ ])?[a-zA-Zàâçéèêëîïôûùüÿñæœ]*)*$/i;

export function validateBirthDay(date) {
  return moment(date).isBetween(moment().subtract(100, 'years'), moment().subtract(24, 'years'));
}

export function validateEmptyValue(value) {
  const errorMessage = '* Ne doit pas être vide.';

  if (!value) {
    return errorMessage;
  }

  if (value && value.value && value.value.length === 0) {
    return errorMessage;
  }

  if (value && value.length === 0) {
    return errorMessage;
  }

  return undefined;
}

export function validateName(value) {
  const isValueEmpty = validateEmptyValue(value);

  if (typeof isValueEmpty !== 'undefined') {
    return isValueEmpty;
  }

  if (value && value.length > 2 && value.match(NAME_VALIDATION_PATTERN)) {
    return undefined;
  }

  return '* Veuillez entrer un nom valide';
}

export function validateSelectRequired(value, message = '* Veuillez remplir ce champ') {
  const isValueEmpty = validateEmptyValue(value);

  if (typeof isValueEmpty !== 'undefined') {
    return isValueEmpty;
  }

  if (
    !!value &&
    !Array.isArray(value) &&
    Object.keys(value).length === 2 &&
    Object.prototype.hasOwnProperty.call(value, 'label') &&
    Object.prototype.hasOwnProperty.call(value, 'value')
  ) {
    return undefined;
  }

  return <span>{message}</span>;
}

export async function validateWithApi(value, api, errorMessage) {
  try {
    const isValid = await api(value);

    if (isValid === null) {
      return 'une erreur est survenue';
    }

    if (isValid) {
      return undefined;
    }

    return errorMessage;
  } catch (error) {
    console.error(error);
    return errorMessage;
  }
}
