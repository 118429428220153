import React, { PureComponent } from 'react';

import AppContext from '../../App/App.context';
import Configuration from '../Configuration';
import Confirmation from '../Confirmation';
import Header from '../Header';
import Footer from '../Footer';
import Selection from '../Selection';
import Stepper from '../Stepper';
import Validation from '../Validation';
import { MobileContentManagerWrapper } from './styles';
import iframe from '../../utils/iframe';

class MobileContentManager extends PureComponent {
  constructor(props) {
    super(props);
    this.steps = [
      {
        name: 'configuration',
        render: () => (
          <Configuration
            onSubmit={brands => {
              this.setState({ brands });
              this.next();
            }}
          />
        ),
        title: 'Complétez vos informations',
        description: 'Pour réserver votre essai gratuit en concession.',
      },
      {
        name: 'selection',
        render: () => {
          const { brands } = this.state;

          return <Selection brands={brands} onButtonNextClick={this.next} />;
        },
        title: 'Choisissez votre modèle',
      },
      {
        name: 'validation',
        render: () => <Validation onButtonPreviousClick={this.previous} onSubmit={this.next} />,
        title: 'Validez votre réservation',
      },
      { name: 'confirmation', render: () => <Confirmation /> },
    ];

    this.state = {
      brands: [],
      currentStep: { ...this.steps[0], index: 0 },
    };
  }

  componentDidMount() {
    this.setState({ currentStep: { ...this.steps[0], index: 0 } });
  }

  setCurrentStepWithIndex = newCurrentStep => {
    const index = this.steps.findIndex(step => step === newCurrentStep);

    this.setState({ currentStep: { ...newCurrentStep, index } });
  };

  next = () => {
    const { currentStep } = this.state;

    this.setCurrentStepWithIndex(this.steps[currentStep.index + 1]);
  };

  previous = () => {
    const { currentStep } = this.state;

    this.setCurrentStepWithIndex(this.steps[currentStep.index - 1]);
  };

  render() {
    const { currentStep } = this.state;

    return (
      <MobileContentManagerWrapper>
        {!iframe ? <Header /> : ''}
        {currentStep.name !== 'confirmation' && <Stepper steps={this.steps.slice(0, -1)} currentStep={currentStep} />}
        {currentStep.render()}
        {!iframe ? <Footer /> : ''}
      </MobileContentManagerWrapper>
    );
  }
}

MobileContentManager.contextType = AppContext;

export default MobileContentManager;
