import styled from 'styled-components';
import { transparentize } from 'polished';

// eslint-disable-next-line import/prefer-default-export
export const FooterSmallWrapper = styled.footer`
  background-color: ${({ theme }) => theme.colors.primary};
  margin-top: 1rem;
  padding: 1rem;
  text-align: center;

  & > * {
    color: ${({ theme }) => transparentize(0.125, theme.colors.white)};
    display: block;
    font-size: 0.625rem;
    font-weight: lighter;
    letter-spacing: 0.005rem;
    line-height: 1.25rem;
  }
`;
