import { getLocations } from './localisation.api';

export async function getLocationsFormatted(filter) {
  try {
    if (filter.length >= 3) {
      const response = await getLocations(filter);

      if (response.status === 200) {
        return response.data.map(locality => ({
          label: `${locality.label} - ${locality.zipcode}`,
          value: locality,
        }));
      }
    }

    return [];
  } catch (error) {
    return [];
  }
}

export const getLocationFormatted = filter => getLocationsFormatted(filter).then(data => data[0]);

export default { getLocationFormatted, getLocationsFormatted };
